.cookies-details {
  background: white;
  padding-top: 80px;
  max-width: 60%;
  margin: 0 auto 60px;
  background-color: unset;
}

.cookies-details .cookies-title {
  font-size: 17px;
  font-weight: bold;
  color: #284a41;
  display: block;
  margin-bottom: 25px;
  margin-top: 20px;
}

.cookies-details .text {
  font-size: 16px;
  font-weight: 400;
  color: #284a41;
}

.cookies-details li {
  font-size: 16px;
  color: #284a41;
}

.cookies-details .subtitle {
  font-size: 16px;
  font-weight: bold;
  color: #284a41;
  display: block;
  margin-bottom: 15px;
  margin-top: 15px;
}
