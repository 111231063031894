
.sub-page::before {
  /* display: block;
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../images/homepage_background2.svg");
  background-size: cover;
  background-size: contain;
  background-origin: border-box;
  background-repeat: no-repeat;
  opacity: 0.2; */
}
@media (min-width: 768px) {
  .sub-page-content {
    padding: 10px 75px 100px 40px;
  }
}