@media (max-width: 573px) {
  .footer {
    height: auto;
  }
}
.footer {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
  font-family: "CarlsbergSansW00-Light";
}

.paragraph-container {
  text-align: center;
}
.paragraph {
  color: black;
  font-weight: 600;
}

.href-to-cancel {
  font-size: 14px;
  margin-top: 0px;
  display: inline;
  margin-bottom: 40px;
  color: black;
  text-decoration: underline;
  font-family: "CarlsbergSansW00-Bold";
}
.link {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  justify-content: space-evenly;
  margin: 0 auto;
  padding-bottom: 40px;
  color: grey;
  text-align: center;
}

.f-link:hover {
  text-decoration: none;
  color: grey;
}
.footer-menu {
  display: flex;
  flex-direction: row;
  text-decoration: none;
  justify-content: space-evenly;
  max-width: 800px;
  min-width: fit-content;
  margin: 0 auto;
  padding-bottom: 20px;
  padding-top: 50px;
  text-align: center;
}
.footer-menu .f-link {
 padding: 0px 9px 10px 9px;
 color: black;
 font-weight: bold;
}
@media (max-width: 768px) {
  .footer-menu {
    padding-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .f-link {
    font-size: 14px;
    padding-bottom: 25px;
  }
  .footer-menu {
    flex-direction: column;
  }
}
@media (max-width: 625px) {
  .footer {
    font-size: 12px;
    line-height: 1.1;
  }
}
