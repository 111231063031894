.bottle-col {
  min-height: 100px;
  display: flex;
  justify-content: center;
}

#root {
  min-height: 100%;
  height: 1px;
}

body {
  background: rgb(196, 207, 216) repeat scroll 0% 0% !important;
}

.bottle-img {
  height: 200px;
  position: relative;
  top: -75px;
  transition: 0.6s;
  filter: drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.8));
}
.bottle-img:hover {
  top: -45px;
  cursor: pointer;
  filter: drop-shadow(2px 4px 8px rgba(0, 0, 0, 0.9));
}
.beer-title {
  color: white;
  font-size: 16px;
  font-weight: 600;
}
.beer-content {
  color: white;
  font-size: 13px;
  line-height: 10px;
}
.unselectable {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.card {
  background-color: transparent;
  border: none;
}

.pcs-board {
  padding-left: 50px;
  padding-right: 50px;
}

@media (max-width: 576px) {
  .pcs-board {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.container-pcs {
  padding: 7px 22px;
  padding-bottom: 10px;
}
.box {
  padding: 7px;
}
.tile-image {
  width: 100%;

  transition: 0.3s;
}

.image-button {
  cursor: pointer;
}
.tilev2-image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 7px;
  opacity: 0;
  transition: 0.7s;
}
.image-box:hover .tilev2-image {
  opacity: 1;
  transition: 0.7s;
}
.image-box:hover .image-button {
  transform: scale(1.1);
  transition: 0.7s;
}

.image-box .image-button:active {
  transform: scale(0.9);
  transition: 0.7s;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

.nav-global {
  padding: 10px 0;
  /* background: #fff; */
}
svg {
  height: 20px;
  width: 20px;
  display: block;
  float: left;
  margin-right: 5px;
  margin-top: -2px;
}
.container-fluid {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  /* min-height: 100; */
  padding: 0;
}
@media (max-width: 576px) {
  .container-fluid {
    padding: 0;
  }
}
.cp-icon {
  width: 50px;
  height: 50px;
  margin-right: 20px;
  fill: #284a41;
}

a:focus {
  outline: none;
}

.news-link {
  font-size: 14px;
  font-family: "ThemeFont-Tertiary", sans-serif;
  text-transform: uppercase;
  font-weight: 900;
  color: #284a41;
  margin-top: 10px;
}

.news-link:hover {
  text-decoration: none;
  color: #284a41;
}

.carousel-indicators li {
  margin: 0 5px;
  height: 5px;
  width: 50px;
  background-color: #284a41;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: none;
}

.wrapper-height {
  height: 1px;
}

.wrapper {
  min-height: 100%;
  background-color: #fff;
  position: relative;
  max-width: 1250px;
  width: 100%;
  margin: 0 auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.wrapper::before {
  display: block;
  content: " ";
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url("../images/homepage_background2.svg");
  background-size: cover;
  background-size: contain;
  background-origin: border-box;
  background-position: right;
  background-repeat: no-repeat;
  opacity: 0.3;
}

.wrapper-background::before {
  background-image: none;
}

@media (max-width: 576px) {
  .wrapper {
    padding: 0;
  }
}
