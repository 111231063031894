@font-face {
  font-family: 'OpenSansCP';
  src: url('../Fonts/CarlsbergSansW05-Light.ttf') format('truetype');
}
.navbar {
  height: 150px;
  background-color: transparent;
  font-family: "CarlsbergSansW05-Black";
}

.navbar-cookies {
  background-color: #fff;
}

@media (max-width: 625px) {
  .navbar {
    height: 100px;
    background-color: white;
  }
}

.img-logo {
  height: 100px;
  margin-left: 10px;
}

@media (max-width: 350px) {
  .img-logo {
    width: 76px;
    height: 35px;
  }
}

.navbar-title {
  color: #115622;
  font-size: 36px;
  margin-left: 20px;
  font-weight: 600;
  font-family: "OpenSansCP";
  font-size: 40px;
}

@media (max-width: 1000px) {
  .navbar-title {
    font-size: 30px;
  }
}
@media (max-width: 625px) {
  .navbar-title {
    font-size: 16px;
    margin-top: 15px;
  }
  .img-logo {
    width: 100px;
    height: fit-content;
  }
}
@media (max-width: 400px) {
  .navbar-title {
    font-size: 14px;
    margin-top: 10px;
  }
}

.navbar-brand {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: top;
}
