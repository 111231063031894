.homepage-rows-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  min-height: 350px;
  width: 100%;
  z-index: 2;
}
.transition {
  transition: 0.5s;
}
.homepage-rows-container .image-button {
  border-radius: 10px;
}
.brands-images-container {
  height: 20vw;
  width: 20vw;
  max-width: 220px;
  max-height: 220px;
  margin: 20px;
  align-self: center;
}
.brands-images-container .brand-image-box {
  width: 100%;
  margin-bottom: 40px;
  overflow: hidden;
  border-radius: 10px;
}
.brands-images-container .brand-image-box .link-with-image img {
  border-radius: 10px;
  transition: 0.7s;
}
.brands-images-container .brand-image-box .link-with-image img:hover {
  transform: scale(1.1);
}

.homepage-rows-container .image-box {
  overflow: hidden;
  max-width: 220px;
  margin: 20px;
  border-radius: 10px;
  width: 20vw;
}

@media (max-width: 450px) {
  .homepage-rows-container {
    min-height: 326px;
  }
}

@media (max-width: 625px) {
  .homepage-rows-container {
    flex-direction: column;
  }
  .brands-images-container {
    display: flex;
    flex-direction: column;
    width: 70vw;
    flex-basis: 50%;
    margin-bottom: 0px;
    margin-top: 0px;
    overflow: hidden;
  }
  .brands-images-container .brand-image-box {
    height: 50%;
    margin: 0px;
    margin-bottom: 10px;
    border-radius: 10px;
    background-color: rgb(45, 103, 52);
    text-align: center;
    overflow: hidden;
  }
  .brands-images-container .brand-image-box .link-with-image img {
    width: auto;
  }
  .homepage-rows-container .image-box {
    width: 70%;
    flex-basis: calc(25% - 10px);
    display: flex;
    justify-content: center;
    background-color: rgb(45, 103, 52);
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .image-box .link-with-image {
    height: 100%;
    width: unset;
    margin: 0 auto;
    text-align: center;
  }

  .link-with-image .tile-image {
    height: 100%;
    width: auto;
  }
  .hidden-brands-container {
    flex-basis: 25%;
    margin-bottom: 0px;
    overflow: hidden;
  }
  .hidden-brands-container .brand-image-box {
    height: 100%;
    margin-bottom: 5px;
  }
  .hidden-brands-container .transition {
    height: 0px;
  }
}

.link-with-image {
  text-decoration: none;
}

.link-with-image:hover {
  text-decoration: none;
}
