.alertBox {
  position: fixed;
  left: 0;
  width: 100%;
  bottom: 0px;
  padding: 30px;
  background-color: #115622;
  opacity: 0.95;
  transition: 1s;
  z-index: 99999;
}

.link {
  font-size: 16px;
  font-weight: 800;
  text-decoration: none;
  color: white !important;
}

.link:hover {
  text-decoration: none;
}

.main-text {
  font-size: 16px;
  color: white;
}

.button-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.button-container .button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 13px;
  font-size: 16px;
  color: white;
  border-radius: 2px;
  height: 46px;
  width: 120px;
  color: black;
  background-color: #8abe1c;
  cursor: pointer;
  transition: 0.3s;
}

.button-container .button:hover {
  background-color: #92c724;
}
